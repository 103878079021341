<template>
  <div class="container-md col-lg-10 col-md-10 pt-2 organisation-list">
    <LoadingOverlay v-if="isCustomerFetch" />
    <div class="pb-5 mb-2" v-else>
      <h3 class="text-primary m-1 pt-1">Organisation List <span class="nowrap" style="font-size: 20px;"
            >({{ organisationList.length || "0" }} Record{{
              organisationList.length > 1 ? "s" : ""
            }})</span
          ></h3>
      
      <CDataTable
        :striped="striped"
        :bordered="bordered"
        :small="small"
        :items="organisations"
        :fields="fields"
        :items-per-page="small ? limit : 5"
        sorter
        :tableFilter="{ label: 'Search', placeholder: 'Type for search' }"
        pagination
        class="border px-1"
      >
        <template #action="{ item, index }">
          <td class="py-2">
            <CButton
              color="primary"
              square
              size="sm"
              class="mr-1"
              v-c-tooltip="'Edit'"
              @click="editOrganisation(item, index)"
              ><i class="far fa-edit"></i
            ></CButton>
          </td>
        </template>
      </CDataTable>
    </div>
    <OrganisationEdit
      v-if="organisationEditModal.isShowPopup"
      :organisationDetail="organisationEditModal.organisation"
      :isShowPopup="organisationEditModal.isShowPopup"
      @modalCallBack="organisationModalCallBack"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import OrganisationEdit from "@/containers/Organisation/EditOrganisation";
export default {
  components: {
    OrganisationEdit,
  },
  data() {
    return {
      limit: 5,
      small: true,
      striped: true,
      bordered: true,
      sorter: { external: true, resetable: true },
      fields: [
        {
          key: "organisation_name",
          label: "Organisation Name",
          _style: "width:30%",
        },
        {
          key: "organisation_type",
          label: "Organisation Type",
          _style: "width:20%",
        },
        {
          key: "city",
          _style: "width:10%",
        },
        {
          key: "main_contact_name",
          label: "Main Contact Name",
          _style: "width:20%;white-space:nowrap",
        },
        { key: "email", label: "Email", _style: "width:20%" },
        {
          key: "action",
          label: "Action",
          sorter: false,
          filter: false,
          _style: "width:10%",
        },
      ],
      organisationEditModal: {
        isShowPopup: false,
        organisation: null,
        organisationID: null,
      },
    };
  },
  computed: {
    ...mapGetters(["organisationList", "isCustomerFetch", "getUser"]),
    organisations() {
      return (
        (this.organisationList &&
          this.organisationList.length &&
          this.organisationList
            .map(({ organisation, ...rest }) => ({
              organisation_name: organisation?.name,
              organisation_type:
                organisation?.organisation_type?.organisation_type,
              main_contact_name: organisation?.main_contact?.name,
              email: organisation?.main_contact?.email,
              organisation_id: organisation?.organisation_id,
              city: rest?.customer_organisation_location
                ? rest?.customer_organisation_location
                    .map((val) => val?.location?.location)
                    .join()
                : "--",
            }))
            .reverse()) ||
        []
      );
    },
  },
  methods: {
    ...mapActions(["fetchOrganisationList", "fetchUser", "updateOrganisation"]),
    editOrganisation(item, index) {
      this.tooltipHide();
      const organisationData = this.organisationList[
        this.organisationList.findIndex(
          (e) => e.organisation_id === item.organisation_id
        )
      ];
      this.organisationEditModal["isShowPopup"] = true;
      this.organisationEditModal["organisation"] = organisationData;
      this.organisationEditModal["organisationID"] = item.organisation_id;
    },
    organisationModalCallBack(action, payload) {
      if (action === false) {
        this.organisationEditModal.isShowPopup = false;
        this.organisationEditModal.organisation = null;
        this.organisationEditModal.organisationID = null;
      } else if (action === true) {
        let appendAction = this.updateOrganisation(payload);
        Promise.all([appendAction]).then((res) => {
          if (res) {
            this.organisationEditModal.isShowPopup = false;
            this.organisationEditModal.organisation = null;
            this.organisationEditModal.organisationID = null;
            this.fetchOrganisationList();
          }
        });
      }
    },
    tooltipHide() {
      setTimeout(() => {
        let x = document.querySelectorAll(".tooltip");
        for (var i = 0; i < x.length; i++) {
          x[i].style.visibility = "hidden";
        }
      }, 50);
    },
  },
  mounted() {
    let appendAction = [];
    if (!(isObject(this.getUser) && !isEmptyObjectCheck(this.getUser)))
      appendAction = [...appendAction, this.fetchUser()];
    Promise.all(appendAction).then((res) => {
      this.fetchOrganisationList();
    });
  },
};
</script>